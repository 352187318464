<template>
    <div class="orgcontainer">
        <h2> راهنمای سایت</h2>
        <div class="topcontent">
            <div class="row">
                <div v-for="i in 0" :key="i" class="card">
                    <img src="../../assets/icons/phonefaq.svg" alt="">
                    <div class="text">
                    <span>  راهنمای ثبت نام </span>
                    <p class="body">
                        برای ارتباط با تیم صرافی از راه های زیر در ارتباط باشید 
                    </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div v-for="(card,index) in cards" :key="index" class="card">
                    <img src="../../assets/icons/phonefaq.svg" alt="">
                    <div class="text">
                    <span> {{card.title}}  </span>
                    <p class="body">
                        {{card.text}}
                    </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="topcontent">
            <span class="faqtitle"> سوالات متداول </span>
            <div class="q-container">
                <div v-for="(item,index) in faq" :key="index" class="questions">
                    <!-- <span class="title"> {{item.question}} </span> -->
                    <div class="question">
                        <h3> {{item.question}} </h3>
                        <span> {{item.answer}} </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'FAQ',
    data() {
        return {
            cards:[
                {
                    title:'راهنمای ثبت نام',
                    text:'برای ارتباط با تیم صرافی از راه های زیر در ارتباط باشید '
                },
                {
                    title:'معامله',
                    text:'با ورود به صفحه معامله با دسترسی سریع به همه ارز ها معامله کنید '
                },
                {
                    title:'کیف پول ها',
                    text:'کیف پول اختصاصی بسازید '
                }
            ],
            faq:[],
        }
    },
    methods: {
        async getFaq(){
            const res = await this.$axios.get('/faq?language=PERSIAN')
            this.faq=res
            console.log(this.faq) ;

        },
    },
    mounted() {
        this.getFaq();
    },
}
</script>

<style lang="scss" scoped>
.q-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 50px;
    width: 100%;
    .questions{
       display: flex;
       flex-direction: column;
       row-gap: 32px;
        width: 40%;
        .title{
            color: var(--dark-blue);
            font-size: clamp(14px,2vw,18px);
            position: relative;
            &::before{
                height: 100%;
                width: 37px;
                content: ' ';
                position: absolute;
                right: -40px;
                background: url('../../assets/icons/FooterItem.svg');
                background-repeat: no-repeat;
            }
        }
        .question{
            span{
                color: var(--grey);
            }
        }
    }
}
.faqtitle{
    font-size: clamp(20px,3vw,24px);
    text-align: center;
}
.topcontent{
    z-index: 5;
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
    .text{
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        .body{
            color: var(--grey);
            font-size: clamp(12px,1vw,14px);
        }
    }
    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 25px;
        justify-content: space-between;
        width: 100%;
        .card{
            display: flex;
            flex-direction: row;
            column-gap: 12px;
            align-items: center;
            background: white;
            padding: 16px;
            border-radius: 12px;
            max-width: 300px;
        }
    }
}
.orgcontainer{
     &::before{
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: url('../../assets/feeBG.png');
        background-repeat: no-repeat;
        background-size: 100%;
    }
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    row-gap: 200px;
    padding: 100px 200px ;
    h2{
        z-index: 5;
        align-self: center;
    }
    
}
@media only screen and(max-width:1000px){
    .orgcontainer{
        padding: 20px 30px ;
    }
}
</style>